import businessConfig from '../config';

const {
  tvaRatio, defaultDeliveryFee, defaultMinOrderAmount, defaultMinTotalCouturier,
  commissionRatio: defaultCommissionRatio, cancelFee, cancelFeeCommission,
} = businessConfig;

export function round(number) {
  return Math.round(number * 100) / 100;
}

export function getAmountWithoutTax(amountWithTax) {
  return round(amountWithTax / (1 + tvaRatio));
}

export function getAmountWithTax(amountWithoutTax) {
  return round(amountWithoutTax * (1 + tvaRatio));
}

export function getTVAFromAmountWithoutTax(amountWithoutTax) {
  return round(amountWithoutTax * tvaRatio);
}

export function getTVAFromAmountWithTax(amountWithTax) {
  const amountWithoutTax = getAmountWithoutTax(amountWithTax);
  return round(amountWithTax - amountWithoutTax);
  // use difference rather than ratio (amountWithTax * 0.2 / 1.2) to avoid issue with rounding
  // cf edge case in unit test with 0.57
}

export function getAlterationUnitPriceWithLinings(item, liningsNumber) {
  const itemPrice = item.price || 0;
  const liningUnitPrice = item.liningUnitPrice || 0;
  return itemPrice + (liningsNumber * liningUnitPrice);
}

export function getAlterationTotal(item, liningsNumber) {
  return item.quantity * getAlterationUnitPriceWithLinings(item, liningsNumber);
}

export function getClothUpcyclingTotal(cloth) {
  const newCloth = typeof cloth.toObject === 'function' ? cloth.toObject() : cloth;
  const { liningsNumber = 0, upcyclingItems = [] } = newCloth;

  return round(upcyclingItems.reduce((sum, item) => {
    const newItem = { ...item, quantity: item.quantity ?? 1 };
    return sum + getAlterationTotal(newItem, liningsNumber);
  }, 0));
}

export function getClothTotal(cloth) {
  const { items = [], customItems = [], liningsNumber = 0 } = cloth;

  return round(items.reduce((sum, item) => sum + getAlterationTotal(item, liningsNumber), 0)
    + customItems.reduce((sum, item) => sum + (parseFloat(item.price) || 0), 0)) + getClothUpcyclingTotal(cloth);
}

export function getTotalPersonalClothes(order) {
  if (!order.clothes) {
    return 0;
  }
  return round(order.clothes.reduce((total, cloth) => total + (cloth.brand ? 0 : getClothTotal(cloth)), 0));
}

export function getTotalBrandClothes(order) {
  if (!order.clothes) {
    return 0;
  }
  return round(order.clothes.reduce((total, cloth) => total + (cloth.brand ? getClothTotal(cloth) : 0), 0));
}

export function getTotalClothes(order) {
  return round(getTotalBrandClothes(order) + getTotalPersonalClothes(order));
}

export function getMinOrderAmountFee(order) {
  const minOrderAmount = (typeof order.minOrderAmount === 'number') ? order.minOrderAmount : defaultMinOrderAmount;
  return round(Math.max(minOrderAmount - getTotalClothes(order), 0));
}

function doesTilliGetDeliveryFee(order) {
  return order.deliverySlot || order.onTheSpot;
}

export function getDeliveryFees(order) {
  return (typeof order.deliveryFee === 'number') ? order.deliveryFee : defaultDeliveryFee;
}

export function getBrandBaseDeliveryFee(order) {
  const deliveryFee = getDeliveryFees(order);
  const totalBrandClothes = getTotalBrandClothes(order);
  const totalPersonalClothes = getTotalPersonalClothes(order);
  if (totalBrandClothes === 0) {
    return 0;
  }
  if (totalPersonalClothes > 0) {
    return round(deliveryFee / 2);
  }
  return deliveryFee;
}

export function getTotalForCustomer(order) {
  return round(getTotalClothes(order) + getDeliveryFees(order) + getMinOrderAmountFee(order));
}

export function getTotalAfterTotalDiscounts(order) {
  const total = getTotalForCustomer(order);
  const rawTotal = order.discounts
    ? order.discounts.reduce((sum, discount) => {
      if (discount.brand) { return sum; }
      if (discount.type !== 'TOTAL') { return sum; }
      if (discount.unit === 'PERCENTAGE') { return sum - ((discount.value / 100) * total); }
      return sum - discount.value;
    }, total)
    : total;
  return Math.max(round(rawTotal), 0);
}

export function getBrandPromoCode(order) {
  if (!order || !order.discounts) {
    return null;
  }
  return order.discounts.find(discount => discount.brand);
}

export function getTotalBaseBrand(order) {
  return getTotalBrandClothes(order) + getBrandBaseDeliveryFee(order);
}

export function getTotalPaidBrand(order, excludeDeliveryFee = false) {
  const brandPromoCode = getBrandPromoCode(order);
  if (!brandPromoCode) return 0;

  const totalBaseBrand = excludeDeliveryFee
    ? getTotalBrandClothes(order)
    : getTotalBaseBrand(order);

  if (brandPromoCode.unit === 'PERCENTAGE') {
    if (brandPromoCode.value === 100) {
      return totalBaseBrand + getMinOrderAmountFee(order);
    }
    return round((brandPromoCode.value / 100) * totalBaseBrand);
  }
  return round(Math.min(totalBaseBrand, brandPromoCode.value));
}

// TODO: unit testing
export function getDeliveryFeePaidBrand(order) {
  return round(getTotalPaidBrand(order) - getTotalPaidBrand(order, true));
}

// TODO: unit testing
export function getDeliveryFeePaidCustomer(order) {
  return round(getDeliveryFees(order) - getDeliveryFeePaidBrand(order));
}

export function getTotalBaseCustomer(order) {
  return round(getTotalForCustomer(order) - getTotalPaidBrand(order));
}

export function getTotalBaseCustomerAfterTotalDiscounts(order) {
  const total = getTotalBaseCustomer(order);
  const rawTotal = order.discounts
    ? order.discounts.reduce((sum, discount) => {
      if (discount.brand) { return sum; }
      if (discount.type !== 'TOTAL') { return sum; }
      if (discount.unit === 'PERCENTAGE') { return sum - ((discount.value / 100) * total); }
      return sum - discount.value;
    }, total)
    : total;
  return Math.max(round(rawTotal), 0);
}

export function getTotalBaseCustomerAfterTotalDiscountsWithoutDiscountVouchers(order) {
  const total = getTotalBaseCustomer(order);
  const rawTotal = order.discounts
    ? order.discounts.reduce((sum, discount) => {
      if (discount.name && (discount.name.toLowerCase().includes('parrainage')
        || discount.name.toLowerCase().includes('filleul'))) {
        return sum;
      }
      if (discount.brand) { return sum; }
      if (discount.type !== 'TOTAL') { return sum; }
      if (discount.unit === 'PERCENTAGE') { return sum - ((discount.value / 100) * total); }
      return sum - discount.value;
    }, total)
    : total;
  return Math.max(round(rawTotal), 0);
}


// TODO: unit testing
export function getDeliveryFeePaidCustomerAfterTotalDiscounts(order) {
  const total = getDeliveryFeePaidCustomer(order);
  const rawTotal = order.discounts
    ? order.discounts.reduce((sum, discount) => {
      if (discount.brand) { return sum; }
      if (discount.type !== 'TOTAL') { return sum; }
      if (discount.unit === 'PERCENTAGE') { return sum - ((discount.value / 100) * total); }
      return sum - discount.value;
    }, total)
    : total;
  return Math.max(round(rawTotal), 0);
}

export function getTotalPaid(order) {
  const total = getTotalBaseCustomerAfterTotalDiscounts(order);
  const rawTotal = order.discounts
    ? order.discounts.reduce((sum, discount) => {
      if (discount.brand) { return sum; }
      if (discount.type === 'TOTAL') { return sum; }
      if (discount.unit === 'PERCENTAGE') { return sum - ((discount.value / 100) * total); }
      return sum - discount.value;
    }, total)
    : total;
  return Math.max(round(rawTotal), 0);
}

export function getTotalPaidWithoutDiscountVouchers(order) {
  const total = getTotalBaseCustomerAfterTotalDiscountsWithoutDiscountVouchers(order);
  const rawTotal = order.discounts
    ? order.discounts.reduce((sum, discount) => {
      if (discount.name && (discount.name.toLowerCase().includes('parrainage')
        || discount.name.toLowerCase().includes('filleul'))) {
        return sum;
      }
      if (discount.brand) { return sum; }
      if (discount.type === 'TOTAL') { return sum; }
      if (discount.unit === 'PERCENTAGE') { return sum - ((discount.value / 100) * total); }
      return sum - discount.value;
    }, total)
    : total;
  return Math.max(round(rawTotal), 0);
}

function getCommissionRatio(order) {
  return typeof order.commissionRatio === 'number'
    ? order.commissionRatio
    : defaultCommissionRatio;
}

export function getCommissionBrand(order) {
  const commissionRatio = getCommissionRatio(order);
  if (doesTilliGetDeliveryFee(order)) {
    return round((getTotalPaidBrand(order, true) * commissionRatio) + getDeliveryFeePaidBrand(order));
  }
  return round(getTotalPaidBrand(order, true) * commissionRatio);
}

export function getCommissionBrandWithTax(order) {
  const commissionRatio = getCommissionRatio(order);
  if (doesTilliGetDeliveryFee(order)) {
    const taxFactor = order.doesBrandPayTVAonAll ? (1 + tvaRatio) : 1;
    const feesWithTax = round(getDeliveryFeePaidBrand(order) * taxFactor);
    return round((getTotalPaidBrand(order, true) * commissionRatio) + feesWithTax);
  }
  return round(getTotalPaidBrand(order, true) * commissionRatio);
}

export function getCommissionCustomer(order) {
  const deliveryFee = getDeliveryFeePaidCustomerAfterTotalDiscounts(order);
  const rawTotalForCommission = getTotalBaseCustomerAfterTotalDiscounts(order) - deliveryFee;
  const commissionRatio = getCommissionRatio(order);
  const commissionWithoutCommissionDiscounts = rawTotalForCommission * commissionRatio;
  let commission = order.discounts
    ? order.discounts.reduce((sum, discount) => {
      if (discount.brand) { return sum; }
      if (discount.type !== 'COMMISSION') { return sum; }
      if (discount.unit === 'PERCENTAGE') { return sum - ((discount.value / 100) * rawTotalForCommission); }
      return sum - discount.value;
    }, commissionWithoutCommissionDiscounts)
    : commissionWithoutCommissionDiscounts;
  if (doesTilliGetDeliveryFee(order)) { commission += deliveryFee; }
  return Math.max(round(commission), 0);
}

export function getCommission(order) {
  return round(getCommissionBrand(order) + getCommissionCustomer(order));
}

export function getTotalCouturierCustomer(order) {
  return round(getTotalPaid(order) - getCommissionCustomer(order));
}

export function getTotalCouturierBrand(order) {
  return round(getTotalPaidBrand(order) - getCommissionBrand(order));
}

export function getMinTotalCouturier(order) {
  return typeof order.minTotalCouturier === 'number'
    ? order.minTotalCouturier
    : defaultMinTotalCouturier;
}

export function getTotalCouturierLostOnCommissionDiscount(order) {
  const newOrder = order.toObject ? order.toObject({ virtuals: false }) : { ...order };
  newOrder.discounts = newOrder.discounts
    ? newOrder.discounts.filter(discount => discount.type !== 'COMMISSION')
    : [];

  const normalTotalCouturier = getTotalCouturierCustomer(newOrder)
    + getTotalCouturierBrand(newOrder);

  const totalCouturier = getTotalCouturierCustomer(order)
    + getTotalCouturierBrand(order);
  return round(normalTotalCouturier - totalCouturier);
}

export function getTotalCouturierTilli(order) {
  const otherTotalCouturier = round(
    getTotalCouturierCustomer(order)
    + getTotalCouturierBrand(order),
  );

  const minTotalCouturier = doesTilliGetDeliveryFee(order)
    ? getMinTotalCouturier(order)
    : getMinTotalCouturier(order) + getDeliveryFees(order);

  const lostOnCommissionDiscount = getTotalCouturierLostOnCommissionDiscount(order);

  const minTotalCouturierWithLostOnDiscount = Math.max(
    round(minTotalCouturier - lostOnCommissionDiscount - otherTotalCouturier), 0,
  );

  return round(minTotalCouturierWithLostOnDiscount + lostOnCommissionDiscount);
}

export function getTotalCouturier(order) {
  return round(
    getTotalCouturierCustomer(order)
    + getTotalCouturierBrand(order)
    + getTotalCouturierTilli(order),
  );
}

export function getTotalCouturierTilliAndCustomer(order) {
  return round(
    getTotalCouturierCustomer(order)
    + getTotalCouturierTilli(order),
  );
}

export function getTotalDiscount(order) {
  return round(getTotalForCustomer(order) - getTotalPaid(order));
}

export function getTotalDiscountPromoCode(order) {
  return round(getTotalForCustomer(order) - getTotalPaidWithoutDiscountVouchers(order));
}

export function getTotalDiscountDiscountVouchers(order) {
  return round(getTotalPaidWithoutDiscountVouchers(order) - getTotalPaid(order));
}

export function getCancelFee(nbCancelling) {
  return round(nbCancelling * cancelFee);
}

export function getUnitCancelFeeCommissionWithoutTax() {
  return cancelFeeCommission;
}

export function getUnitCancelFeeCommissionWithTax() {
  return getAmountWithTax(cancelFeeCommission);
}

export function getCancelFeeCommissionWithoutTax(nbCancelling) {
  return round(nbCancelling * cancelFeeCommission);
}

export function getCancelFeeCommissionWithTax(nbCancelling) {
  return round(nbCancelling * getUnitCancelFeeCommissionWithTax());
}

export function getCommissionCustomerWithCancelFeeWithTax(order, nbCancelling) {
  return round(getCommissionCustomer(order) + getCancelFeeCommissionWithTax(nbCancelling));
}

export function getTotalCouturierCustomerWithCancelFeeWithTax(order, nbCancelling) {
  const cancelFeeCouturier = getCancelFee(nbCancelling) - getCancelFeeCommissionWithTax(nbCancelling);
  return round(getTotalCouturierCustomer(order) + cancelFeeCouturier);
}

export function getTaxOnCommission(order) {
  return getTVAFromAmountWithTax(getCommission(order));
}

export function getTaxOnCommissionBrand(order) {
  return getTVAFromAmountWithTax(getCommissionBrand(order));
}

export function getTaxOnCommissionCustomer(order) {
  return getTVAFromAmountWithTax(getCommissionCustomer(order));
}

export function getTotalPaidBrandWithTax(order) {
  if (order.doesBrandPayTVAonAll) {
    const taxFactor = 1 + tvaRatio;
    const commissionRatio = getCommissionRatio(order);
    const taxOnCommission = getTVAFromAmountWithTax(round(getTotalPaidBrand(order, true) * commissionRatio));
    const baseWithoutTax = getTotalPaidBrand(order) - taxOnCommission;
    return round(taxFactor * baseWithoutTax);
  }
  return getTotalPaidBrand(order);
}

export function getTotalWithoutTax(order) {
  const taxOnCommission = getTaxOnCommission(order);
  return getTotalForCustomer(order) - taxOnCommission;
}

export function getTaxCustomer(order) {
  return getTaxOnCommissionCustomer(order);
}

export function getTaxBrand(order) {
  const taxOnCommissionBrand = getTaxOnCommissionBrand(order);
  if (order.doesBrandPayTVAonAll) {
    const baseWithoutTax = getTotalPaidBrand(order) - taxOnCommissionBrand;
    return round((tvaRatio * baseWithoutTax));
  }
  return taxOnCommissionBrand;
}

export function getTax(order) {
  return round(getTaxCustomer(order) + getTaxBrand(order));
}

export function getTotalWithTax(order) {
  const tax = getTax(order);
  return round(getTotalWithoutTax(order) + tax);
}

// DEPRECATED: not clear enough (with tax for commission / without tax for opaque B2B)
export function getTotal(order) {
  return getTotalForCustomer(order);
}
